<template>
    <b-card :id="cardId" class="card-m-h mb-4 list-container" body-class="px-0">
        <b-row class="mb-1 px-3">
            <b-col>
                <h4 class="heavy d-inline mr-4">{{ t('orderNotifications') }}</h4>
                <span class="text-gray-dark"> {{ t('viewingAllOrders') }}</span>
                <Filters
                    :boundary-id="cardId"
                    class="float-right"
                    @change="refreshRecords(1, $event)"
                />
            </b-col>
        </b-row>
        <Table
            :items="notifications"
            :queryParams="queryParams"
            @sort-changed="sortChanged"
            v-on="$listeners"
        />
        <div class="d-flex justify-content-end px-3">
            <b-pagination
                v-if="pageDetails.total"
                @change="refreshRecords"
                align="right"
                size="sm"
                class="mb-0"
                :value="queryParams.page"
                :total-rows="pageDetails.total"
                :per-page="queryParams.perPage"
            ></b-pagination>
        </div>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex';
import snakeCase from 'lodash/snakeCase';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import Table from './OrderNotificationTable.vue';
import Filters from './OrderNotificationFilters.vue';

const defaultSort = {
    sortOrder: 'desc',
    sortBy: 'date',
};
const defaultQueryParams = () => ({
    search: null,
    page: 1,
    perPage: 5,
    ...defaultSort,
});

export default {
    name: 'ReservationNotifications',
    components: {Table, Filters},
    data() {
        return {
            cardId: 'orders-section',
            notifications: [],
            pageDetails: {
                total: 0,
                totalPages: 0,
            },
            queryParams: defaultQueryParams(),
        };
    },
    computed: {
        ...mapGetters({
            activeCustomerOcosNumberOrId: 'user/activeCustomerOcosNumberOrId',
        }),
        queryObject() {
            // Removing empty property
            return Object.keys(this.queryParams)
                .filter((key) => !isNil(this.queryParams[key]))
                .reduce((acc, key) => ({...acc, [snakeCase(key)]: this.queryParams[key]}), {});
        },
    },
    watch: {
        async activeCustomerOcosNumberOrId(value) {
            if (!isNil(value)) {
                await Promise.all([this.refreshRecords(1)]);
            }
        },
    },
    methods: {
        transformQuery(params, addPrefix) {
            return Object.keys(params)
                .filter((k) => addPrefix || k.includes('ord-'))
                .reduce(
                    (acc, c) => (
                        (acc[addPrefix ? `ord-${c}` : c.replace('ord-', '')] = params[c]), acc
                    ),
                    {}
                );
        },
        async refreshRecords(page = this.queryParams.page, params = {}) {
            if (!this.activeCustomerOcosNumberOrId) return;
            this.queryParams = {...this.queryParams, ...params, page};
            const query = this.transformQuery(this.queryParams, true);
            this.$router
                .replace({
                    query: {...this.$route.query, ...query},
                })
                .catch(() => {});
            try {
                this.setSpinner(true);
                const {
                    data: {success, results, error},
                    headers,
                } = await this.$http.get(`notifications/lensorder`, {
                    params: this.queryObject,
                });

                if (!success) throw error;
                this.pageDetails = {
                    total: Number(headers['x-orion-total']) || 0,
                    totalPages: Number(headers['x-orion-totalpages']) || 0,
                };
                this.notifications = results;
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCodeDescription || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            } finally {
                this.setSpinner(false);
            }
        },
        async sortChanged(context) {
            if (!context.sortBy) return;
            this.queryParams.sortBy = context.sortBy;
            this.queryParams.sortOrder = context.sortDesc ? 'desc' : 'asc';
            await this.refreshRecords(1);
        },
    },
    async mounted() {
        this.queryParams = merge(
            {},
            this.queryParams,
            omit(this.transformQuery(this.$route.query, false), ['perPage'])
        );
        await this.refreshRecords();
    },
};
</script>
