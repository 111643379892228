<template>
    <b-container fluid class="dashboard-container pt-4">
        <b-row class="header-row">
            <b-col
                xl="9"
                class="d-flex flex-column align-items-stretch justify-content-between flex-lg-row align-items-lg-center"
            >
                <GreetUser class="mb-2 mb-lg-0" />

                <div
                    class="d-flex flex-column align-items-start justify-content-end flex-grow flex-sm-row align-items-sm-center ml-lg-4"
                >
                    <b-input-group style="min-width: 19em" class="mb-2 mb-sm-0">
                        <b-input-group-prepend is-text @click="searchChanged">
                            <b-icon icon="search"></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                            type="search"
                            v-model="search"
                            @search="searchChanged"
                            :placeholder="t('notificationList_SearchPlaceholder')"
                        ></b-form-input>
                    </b-input-group>
                    <b-button
                        variant="primary"
                        to="NewPatient"
                        class="no-wrap ml-sm-3"
                        v-if="
                            checkPermissions({
                                [PERMISSIONS.CREATE_PATIENT]: PERMISSIONS_VALUES.ENABLED,
                            })
                        "
                    >
                        <div><b-icon-plus class="mr-2" /> {{ t('addNewPatient') }}</div>
                    </b-button>
                </div>
            </b-col>
            <b-col xl="3" class="d-flex align-items-center">
                <h2 class="ann-header-max text-primary mb-0 text-break d-none d-xl-block">
                    {{ t('announcements') }}
                </h2>
            </b-col>
        </b-row>
        <b-row>
            <!-- MAIN COL - HEADER -->
            <b-col xl="9" class="mb-4">
                <ReservationNotifications
                    ref="reservationNotifications"
                    @change-status="setNotificationStatus($event, 'reservationNotifications')"
                />
                <OrderNotifications
                    v-if="hasOrderingPermission"
                    ref="orderNotifications"
                    @change-status="setNotificationStatus($event, 'orderNotifications')"
                />
            </b-col>

            <!-- SIDE COL - CONTENT -->
            <b-col xl="3">
                <b-row class="d-xl-none header-row">
                    <b-col>
                        <h2 class="text-primary mb-0 text-break">{{ t('announcements') }}</h2>
                    </b-col>
                </b-row>

                <b-row
                    v-for="annoucement in announcements"
                    :key="annoucement.timestamp"
                    class="ann"
                >
                    <b-col cols="12">
                        <b-card class="card-m-h mb-4">
                            <h5 class="text-caption ann-title-fontsize">
                                <div v-if="hasAnnouncmentUrl(annoucement)">
                                    <a :href="annoucement.url" target="_blank">
                                        {{ annoucement.title }}
                                    </a>
                                </div>
                                <div v-else>
                                    <h5 class="ann-no-title-url">
                                        {{ annoucement.title }}
                                    </h5>
                                </div>
                            </h5>
                            <div class="ann-body-overflow-y">
                                <ImagePlaceholder class-name="ann-image" :src="annoucement.image">
                                    <img class="ann-image" src="@/assets/placeholder.png" />
                                </ImagePlaceholder>
                                <div v-html="annoucement.body"></div>
                            </div>
                            <div class="ann-date">
                                <small>{{ annoucement.displayDate | date }}</small>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <p class="text-right font-weight-lighter">
            <small>
                <span class="float-left"> {{ t('disclaimer') }}</span>
                Stella version 2.00
            </small>
        </p>
    </b-container>
</template>

<script>
import isNil from 'lodash/isNil';
import {mapGetters} from 'vuex';
import GreetUser from '@/components/GreetUser.vue';
import ImagePlaceholder from '@/components/ImagePlaceholder.vue';
import ReservationNotifications from './components/reservationnotifications/Index.vue';
import OrderNotifications from './components/ordernotifications/Index.vue';

const NOTIFICATION_STATUS = {
    INVALID: 'Invalid',
    UNREAD: 'Unread',
    READ: 'Read',
    DISMISSED: 'Dismissed',
};

export default {
    components: {GreetUser, ReservationNotifications, OrderNotifications, ImagePlaceholder},
    name: 'Dashboard',
    data() {
        return {
            notifications: [],
            search: null,
            announcements: [],
        };
    },
    computed: {
        ...mapGetters('user', ['activeCustomerOcosNumberOrId']),
        hasOrderingPermission() {
            //only show order notification panel if they have ORDERS set
            //to read-only or read-write
            return this.checkPermissions({
                [this.PERMISSIONS.ORDERS]: [
                    this.PERMISSIONS_VALUES.READ_WRITE,
                    this.PERMISSIONS_VALUES.READ_ONLY,
                ],
            });
        },
    },
    watch: {
        async activeCustomerOcosNumberOrId(value) {
            if (!isNil(value)) {
                await Promise.all([this.fetchAnnouncements()]);
            }
        },
    },
    methods: {
        hasAnnouncmentUrl(annoucement) {
            //console.log(annoucement);
            return annoucement.url;
        },
        async setNotificationStatus(item, refKey) {
            if (item.status === NOTIFICATION_STATUS.READ) return;
            try {
                this.setSpinner(true);
                const {success, error} = (
                    await this.$http.post(`notifications/${item.userNotificationId}`, {
                        status: NOTIFICATION_STATUS.READ,
                    })
                ).data;
                if (!success) throw error;
                await this.$refs[refKey].refreshRecords(1);
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCodeDescription || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            } finally {
                this.setSpinner(false);
            }
        },
        async fetchAnnouncements() {
            try {
                const {success, error, results} = (await this.$http.get(`announcements`)).data;
                if (!success) throw error;
                this.announcements = results;
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCodeDescription || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            }
        },
        async searchChanged() {
            ['reservationNotifications', 'orderNotifications'].forEach(async (refKey) => {
                this.$refs[refKey].queryParams.search = this.search;
                await this.$refs[refKey].refreshRecords(1);
            });
        },
    },
    async mounted() {
        this.search = this.$route.query['res-search'] || this.$route.query['ord-search'];

        // Fetch the notifications if an active customer has been set
        if (!isNil(this.activeCustomerOcosNumberOrId)) {
            await Promise.all([this.fetchAnnouncements()]);
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables';

.ann-header-max {
    font-size: 2.4rem;
}

::v-deep .ann {
    .card-body {
        padding-bottom: 0px;
    }

    .ann-body-overflow-y {
        height: 274px;
        overflow-y: auto;
    }

    .ann-title-fontsize {
        font-size: medium;
        height: 35px;
        margin-bottom: 0px;
        overflow-y: auto;
    }

    .ann-image {
        height: 100px;
        max-width: 173px;
        float: left;
        margin-right: 15px;
    }

    .ann-date {
        margin-top: 8px;
        display: flex;
        * {
            margin-left: auto;
        }
    }

    .ann-no-title-url {
        font-weight: normal;
        color: $primary;
    }
}
</style>
